<template>
  <div class="content-wrapper">
    <div class="content-lookup">  <!-- 필터 -->
      <div class="lookup-left">
        <ul class="lookup-condition"> 필터조건 추가 </ul>
        <div class="lookup-lookup"> 필터 좌측 버튼 </div>
      </div>
      <div class="lookup-right"> <!-- 사용시 좌측이 없을 경우 생략-->
        <ul class="lookup-button"> 필터 우측버튼 </ul>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">그리드 제목</div>
              <div class="header-caption">[0건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                그리드 위에 버튼영역
              </ul>
            </div>
          </div>
          <div class="section-body">
            그리드
          </div>
        </section>
      </article>
    </div>
    <!-- 각종팝업 -->
  </div>
</template>
<style scoped>

</style>

<script>

export default {
  name: "OilStockStatus",
  created() {
  },
  data() {
    return {
      //new edit start
    };
  },
  computed: {},
  methods: {

  }

};
</script>